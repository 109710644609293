import React, { useState } from 'react';

const Carousel = ({ title, items, renderItem, refreshButton, type = 'default' }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const scrollRef = React.useRef(null);

  const handleDotClick = (index) => {
    if (scrollRef.current) {
      const { current } = scrollRef;
      const itemWidth = current.offsetWidth;
      const newPosition = index * itemWidth;
      current.scrollTo({ left: newPosition, behavior: 'smooth' });
      setCurrentIndex(index);
    }
  };

  const handleScroll = (e) => {
    if (scrollRef.current) {
      const { scrollLeft, offsetWidth } = e.target;
      const newIndex = Math.round(scrollLeft / offsetWidth);
      setCurrentIndex(newIndex);
    }
  };

  const isFullWidth = type === 'blog' || type === 'education';

  return (
    <div className="mb-8">
      <div className="flex justify-between items-center mb-4 px-4">
        <h2 className="text-xl font-bold text-gray-900">{title}</h2>
        {refreshButton}
      </div>
      
      <div className="relative">
        <div
          ref={scrollRef}
          onScroll={handleScroll}
          className={`flex overflow-x-auto scrollbar-hide snap-x snap-mandatory`}
          style={{ scrollbarWidth: 'none', msOverflowStyle: 'none' }}
        >
          {items.map((item, index) => (
            <div 
              key={index} 
              className={`flex-none snap-center ${isFullWidth ? 'w-full' : 'w-64'} px-4`}
            >
              {renderItem(item)}
            </div>
          ))}
        </div>

        {/* Dots */}
        <div className="flex justify-center gap-2 mt-4">
          {items.map((_, index) => (
            <button
              key={index}
              onClick={() => handleDotClick(index)}
              className={`w-2 h-2 rounded-full transition-all ${
                currentIndex === index 
                  ? 'bg-[#56C5B9] w-4' 
                  : 'bg-gray-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;