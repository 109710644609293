// src/pages/AppleCallback.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export default function AppleCallback() {
  const navigate = useNavigate();
  const { login } = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      try {
        // Check for token and user data
        const token = localStorage.getItem('token');
        const storedUser = localStorage.getItem('user');

        if (!token || !storedUser) {
          throw new Error('Missing authentication data');
        }

        const userData = JSON.parse(storedUser);
        await login({ token, user: userData });

        // Force redirect to onboarding if profile is not complete
        if (!userData.isProfileComplete) {
          navigate('/onboarding', { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      } catch (error) {
        console.error('Apple callback error:', error);
        navigate('/login', { replace: true });
      }
    };

    handleCallback();
  }, [navigate, login]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="text-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9] mx-auto mb-4" />
        <p className="text-gray-600">Setting up your account...</p>
      </div>
    </div>
  );
}