import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  RefreshCcw, 
  HelpCircle, 
  ActivitySquare, 
  Stethoscope,
  Salad,
  Dumbbell
} from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import Carousel from '../components/Carousel';
import { getRecommendations } from '../api/recipe';

export default function HomePage() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [loadingRecipes, setLoadingRecipes] = useState(true);

  // Dummy data for articles
  const blogs = [
    { id: 1, title: 'New Research on Lipedema Treatment', content: 'Recent studies have shown promising results in managing lipedema symptoms through combined therapy approaches...', image: '/article1.jpg' },
    { id: 2, title: 'Understanding the Stages of Lipedema', content: 'Learn about the different stages of lipedema and how to identify progression...', image: '/article2.jpg' },
    { id: 3, title: 'Diet Tips for Lipedema Management', content: 'Discover which foods can help reduce inflammation and manage lipedema symptoms...', image: '/article3.jpg' }
  ];

  const topics = [
    { 
      id: 1, 
      title: 'What is Lipedema?',
      description: 'Understanding the basics of lipedema, its causes, and risk factors',
      icon: HelpCircle,
      color: 'bg-pink-100',
      borderColor: 'border-pink-200',
      iconColor: 'text-pink-500'
    },
    { 
      id: 2, 
      title: 'Stages & Symptoms',
      description: 'Learn about different stages and how to identify symptoms',
      icon: ActivitySquare,
      color: 'bg-purple-100',
      borderColor: 'border-purple-200',
      iconColor: 'text-purple-500'
    },
    { 
      id: 3, 
      title: 'Treatment Options',
      description: 'Explore various treatment approaches and therapies',
      icon: Stethoscope,
      color: 'bg-blue-100',
      borderColor: 'border-blue-200',
      iconColor: 'text-blue-500'
    },
    { 
      id: 4, 
      title: 'Diet Guidelines',
      description: 'Recommended foods and nutrition tips for management',
      icon: Salad,
      color: 'bg-green-100',
      borderColor: 'border-green-200',
      iconColor: 'text-green-500'
    },
    { 
      id: 5, 
      title: 'Exercise Tips',
      description: 'Safe and effective exercises for lipedema patients',
      icon: Dumbbell,
      color: 'bg-yellow-100',
      borderColor: 'border-yellow-200',
      iconColor: 'text-yellow-500'
    }
  ];

  useEffect(() => {
    loadRecipes();
  }, []);

  const loadRecipes = async () => {
    try {
      setLoadingRecipes(true);
      const data = await getRecommendations();
      setRecipes(data);
    } catch (error) {
      console.error('Error loading recipes:', error);
    } finally {
      setLoadingRecipes(false);
    }
  };

  const renderBlogCard = (blog) => (
    <div 
      onClick={() => window.open(`/blog/${blog.id}`, '_blank')}
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer h-full"
    >
      <div className="h-48 bg-gray-200">
        <img 
          src={blog.image} 
          alt={blog.title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-medium mb-2">{blog.title}</h3>
        <p className="text-gray-600 line-clamp-3">{blog.content}</p>
      </div>
    </div>
  );

  const renderTopicCard = (topic) => {
    const Icon = topic.icon;
    return (
      <div
        onClick={() => navigate(`/education/${topic.id}`)}
        className={`${topic.color} border-2 ${topic.borderColor} p-6 rounded-xl cursor-pointer transition-all hover:scale-[1.02] h-full`}
      >
        <div className={`${topic.iconColor} mb-4`}>
          <Icon size={32} />
        </div>
        <h3 className="text-lg font-medium mb-2">{topic.title}</h3>
        <p className="text-gray-600">{topic.description}</p>
      </div>
    );
  };

  const renderRecipeCard = (recipe) => (
    <div 
      onClick={() => navigate(`/recipes/${recipe._id}`)}
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer relative h-full"
    >
      <div className="h-48 bg-gray-200">
        <img 
          src={recipe.image} 
          alt={recipe.title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="p-6">
        <h3 className="text-xl font-medium mb-2">{recipe.title}</h3>
        {recipe.matchScore && (
          <div className="flex items-center gap-2 mt-4">
            <div className="h-2 flex-1 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className="h-full bg-green-500 rounded-full transition-all"
                style={{ width: `${recipe.matchScore}%` }}
              />
            </div>
            <span className="text-sm text-gray-600 whitespace-nowrap">{recipe.matchScore}% match</span>
          </div>
        )}
      </div>
      {recipe.matchScore > 80 && (
        <div className="absolute top-4 right-4 bg-green-500 text-white px-3 py-1 rounded-full text-sm font-medium">
          Perfect Match
        </div>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      {/* Welcome Section */}
      <div className="bg-[#56C5B9] pt-8 pb-8 px-4">
        <div className="max-w-xl mx-auto">
          <h1 className="text-2xl font-bold text-white mb-2">
            Welcome, {user?.username}!
          </h1>
          <p className="text-white/90">
            Track your food journey and connect with the community
          </p>
        </div>
      </div>

      <div className="max-w-xl mx-auto mt-6">
        {/* Latest Blogs Section */}
        <Carousel
          title="Latest Blogs"
          items={blogs}
          renderItem={renderBlogCard}
          type="blog"
        />

        {/* Understanding Lipedema Section */}
        <Carousel
          title="Understanding Lipedema"
          items={topics}
          renderItem={renderTopicCard}
          type="education"
        />

        {/* Recipes Section */}
        {loadingRecipes ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
          </div>
        ) : (
          <Carousel
            title="Recipes for You"
            items={recipes}
            renderItem={renderRecipeCard}
            refreshButton={
              <button 
                onClick={loadRecipes}
                className="flex items-center gap-2 text-[#56C5B9] hover:text-[#4AA69B]"
              >
                <RefreshCcw size={16} />
                <span className="text-sm font-medium">Refresh</span>
              </button>
            }
          />
        )}
      </div>
    </div>
  );
}