import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

export default function TermsOfServicePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  
  const handleBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else if (user) {
      navigate(-1);
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto flex items-center gap-4">
          <button onClick={handleBack} className="text-white">
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold text-white">Terms of Service</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg p-6 space-y-6">
          <section>
            <h2 className="text-xl font-bold mb-3">1. Acceptance of Terms</h2>
            <p className="text-gray-700">
              By accessing or using our app, you agree to be bound by these Terms of Service. 
              If you disagree with any part of these terms, you may not access the service.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">2. User Responsibilities</h2>
            <p className="text-gray-700 mb-2">You agree to:</p>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Provide accurate and complete information</li>
              <li>Maintain the security of your account</li>
              <li>Use the service in compliance with all applicable laws</li>
              <li>Not misuse or abuse the service or other users</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">3. Medical Disclaimer</h2>
            <p className="text-gray-700">
              Our app provides general dietary information for individuals with lipedema. 
              It is not a substitute for professional medical advice, diagnosis, or treatment. 
              Always seek the advice of your physician or other qualified health provider 
              with any questions about your medical condition.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">4. Community Guidelines</h2>
            <p className="text-gray-700 mb-2">When using community features, you must not:</p>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Post harmful or inappropriate content</li>
              <li>Harass or bully other users</li>
              <li>Share false or misleading information</li>
              <li>Violate any intellectual property rights</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">5. Content Ownership</h2>
            <p className="text-gray-700">
              You retain rights to content you post, but grant us a license to use, 
              modify, and display it in connection with the service. You are responsible 
              for ensuring you have the necessary rights to share any content.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">6. Service Modifications</h2>
            <p className="text-gray-700">
              We reserve the right to modify or discontinue any part of our service 
              without notice. We are not liable to you or any third party for any 
              modification, suspension, or discontinuation.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">7. Termination</h2>
            <p className="text-gray-700">
              We may terminate or suspend your account and access to the service 
              immediately, without prior notice, for conduct that we believe violates 
              these Terms or is harmful to other users, us, or third parties.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">8. Limitation of Liability</h2>
            <p className="text-gray-700">
              To the maximum extent permitted by law, we shall not be liable for any 
              indirect, incidental, special, consequential, or punitive damages resulting 
              from your use or inability to use the service.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">9. Contact</h2>
            <p className="text-gray-700">
              If you have any questions about these Terms, please contact us at lipeplandietapp@gmail.com
            </p>
          </section>

          <div className="text-sm text-gray-500 pt-4">
            Last updated: {new Date().toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
}