import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { getFoodById } from '../api/food';
import { updatePreference, getUserPreferences } from '../api/preferences';

export default function FoodDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [food, setFood] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userPreference, setUserPreference] = useState(null);

  useEffect(() => {
    loadFoodData();
  }, [id]);

  const loadFoodData = async () => {
    try {
      const [foodData, preferencesData] = await Promise.all([
        getFoodById(id),
        getUserPreferences()
      ]);
      
      setFood(foodData);
      const userPref = preferencesData.find(p => p.food._id === id);
      setUserPreference(userPref);
    } catch (error) {
      console.error('Error loading food:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePreferenceUpdate = async (status) => {
    try {
      await updatePreference(id, status);
      loadFoodData();
    } catch (error) {
      console.error('Error updating preference:', error);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto flex items-center gap-4">
          <button onClick={() => navigate(-1)} className="text-white">
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold text-white">{food?.name}</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        <div className="bg-white rounded-lg shadow-lg p-6">
          <div className="flex flex-wrap gap-2 mb-4">
            <span className="px-3 py-1 bg-gray-100 rounded-full text-sm">
              {food?.category}
            </span>
            {food?.antiInflammatory && (
              <span className="px-3 py-1 bg-green-100 text-green-800 rounded-full text-sm">
                Anti-inflammatory
              </span>
            )}
          </div>

          <p className="text-gray-700 mb-6">{food?.description}</p>

          <div className="grid grid-cols-4 gap-4 mb-8">
            <div className="text-center p-4 bg-gray-50 rounded-lg">
              <p className="text-xl font-bold">{food?.nutritionalInfo.calories}</p>
              <p className="text-sm text-gray-500">Calories</p>
            </div>
            <div className="text-center p-4 bg-gray-50 rounded-lg">
              <p className="text-xl font-bold">{food?.nutritionalInfo.protein}g</p>
              <p className="text-sm text-gray-500">Protein</p>
            </div>
            <div className="text-center p-4 bg-gray-50 rounded-lg">
              <p className="text-xl font-bold">{food?.nutritionalInfo.carbs}g</p>
              <p className="text-sm text-gray-500">Carbs</p>
            </div>
            <div className="text-center p-4 bg-gray-50 rounded-lg">
              <p className="text-xl font-bold">{food?.nutritionalInfo.fats}g</p>
              <p className="text-sm text-gray-500">Fats</p>
            </div>
          </div>

          <h2 className="text-lg font-bold mb-4">Community Feedback</h2>
          <div className="grid grid-cols-3 gap-4 mb-6 bg-gray-50 p-4 rounded-lg">
            <div className="text-center">
              <p className="text-2xl font-bold text-green-600">
                {Math.round((food?.stats?.ok / (food?.stats?.total || 1)) * 100)}%
              </p>
              <p className="text-sm text-gray-500">Good for Me</p>
            </div>
            <div className="text-center">
              <p className="text-2xl font-bold text-yellow-600">
                {Math.round((food?.stats?.sensitive / (food?.stats?.total || 1)) * 100)}%
              </p>
              <p className="text-sm text-gray-500">Cautious</p>
            </div>
            <div className="text-center">
              <p className="text-2xl font-bold text-red-600">
                {Math.round((food?.stats?.avoid / (food?.stats?.total || 1)) * 100)}%
              </p>
              <p className="text-sm text-gray-500">Avoid</p>
            </div>
          </div>

          <div className="flex gap-2">
            <button
              onClick={() => handlePreferenceUpdate('ok')}
              className={`flex-1 py-3 rounded-lg ${
                userPreference?.status === 'ok'
                  ? 'bg-green-500 text-white'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              Good for Me
            </button>
            <button
              onClick={() => handlePreferenceUpdate('sensitive')}
              className={`flex-1 py-3 rounded-lg ${
                userPreference?.status === 'sensitive'
                  ? 'bg-yellow-500 text-white'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              Cautious
            </button>
            <button
              onClick={() => handlePreferenceUpdate('avoid')}
              className={`flex-1 py-3 rounded-lg ${
                userPreference?.status === 'avoid'
                  ? 'bg-red-500 text-white'
                  : 'bg-gray-100 hover:bg-gray-200'
              }`}
            >
              Avoid
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}