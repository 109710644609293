// src/api/post.js
import api from './config';

export const getFeedPosts = async (page = 1, limit = 10) => {
  try {
    const response = await api.get(`/api/posts?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const getPost = async (postId) => {
  try {
    const response = await api.get(`/api/posts/${postId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const deletePost = async (postId) => {
  try {
    const response = await api.delete(`/api/posts/${postId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const deleteComment = async (postId, commentId) => {
  try {
    const response = await api.delete(`/api/posts/${postId}/comments/${commentId}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};


export const createPost = async (formData) => {
  try {
    const response = await api.post('/api/posts', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Error creating post' };
  }
};

export const likePost = async (postId) => {
  try {
    const response = await api.post(`/api/posts/${postId}/like`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Error liking post' };
  }
};

export const addComment = async (postId, content) => {
  try {
    const response = await api.post(`/api/posts/${postId}/comment`, { content });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Error adding comment' };
  }
};