// src/pages/OnboardingFlow.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Info } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import api from '../api/config';

export default function OnboardingFlow() {
  const [step, setStep] = useState(1);
  const [showInfo, setShowInfo] = useState(false);
  const navigate = useNavigate();
  const { user, login } = useAuth();
  
  const [formData, setFormData] = useState({
    fullName: '',
    username: '',
    gender: '',
    age: '',
    height: '',
    weight: '',
    activityLevel: '',
    goal: ''
  });

  const isStepOneValid = formData.fullName && formData.username;
  const isStepTwoValid = formData.gender && formData.age && formData.height && formData.weight;
  const isStepThreeValid = formData.activityLevel && formData.goal;

const calculateBMI = (height, weight) => {
  if (!height || !weight) return null;
  const heightInM = height / 100;
  return (weight / (heightInM * heightInM)).toFixed(1);
};

const calculateMacros = (profile) => {
  if (!profile || !profile.weight) return null;

  const leanFactor = profile.gender === 'male' ? 0.85 : 0.80;
  const leanMass = profile.weight * leanFactor;
  let bmr = 370 + (21.6 * leanMass);

  const activityMultipliers = {
    'sedentary': 1.2,
    'light': 1.375,
    'moderate': 1.55,
    'intense': 1.725
  };

  const tdee = bmr * (activityMultipliers[profile.activityLevel] || 1.2);

  // Default to maintenance
  let calorieTarget = tdee;
  if (profile.dietGoal === 'lose') calorieTarget *= 0.8;
  if (profile.dietGoal === 'gain') calorieTarget *= 1.1;

  return {
    calories: Math.round(calorieTarget),
    protein: Math.round((calorieTarget * 0.3) / 4),
    carbs: Math.round((calorieTarget * 0.4) / 4),
    fats: Math.round((calorieTarget * 0.3) / 9)
  };
};

  const handleSubmit = async () => {
    const bmi = calculateBMI(formData.height, formData.weight);
    const macros = calculateMacros({
      gender: formData.gender,
      weight: formData.weight,
      activityLevel: formData.activityLevel,
      dietGoal: formData.goal
    });

    const userData = {
      profile: {
        fullName: formData.fullName,
        gender: formData.gender,
        age: parseInt(formData.age),
        height: parseInt(formData.height),
        weight: parseInt(formData.weight),
        activityLevel: formData.activityLevel,
        dietGoal: formData.goal,
        bmi,
        macros
      },
      username: formData.username,
      isProfileComplete: true
    };

    try {
      console.log('Sending profile update:', userData);
      const response = await api.post('/api/users/profile', userData);  // Changed from put to post
      
      if (response.data) {
        const updatedUser = {
          ...user,
          ...response.data,
          isProfileComplete: true
        };
        
        localStorage.setItem('user', JSON.stringify(updatedUser));
        await login({ token: localStorage.getItem('token'), user: updatedUser });
        navigate('/', { replace: true });
      }
    } catch (error) {
      console.error('Error saving profile:', error);
      alert('Failed to save profile. Please try again.');
    }
  };

  const renderStep = () => {
    switch(step) {
      case 1:
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-gray-900">Let's get to know each other!</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Full Name</label>
                <input
                  type="text"
                  value={formData.fullName}
                  onChange={(e) => setFormData({...formData, fullName: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Username</label>
                <input
                  type="text"
                  value={formData.username}
                  onChange={(e) => setFormData({...formData, username: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                />
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-gray-900">BMI Index</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                <select
                  value={formData.gender}
                  onChange={(e) => setFormData({...formData, gender: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                >
                  <option value="">Select gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Age</label>
                <input
                  type="number"
                  value={formData.age}
                  onChange={(e) => setFormData({...formData, age: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Height (cm)</label>
                <input
                  type="number"
                  value={formData.height}
                  onChange={(e) => setFormData({...formData, height: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Weight (kg)</label>
                <input
                  type="number"
                  value={formData.weight}
                  onChange={(e) => setFormData({...formData, weight: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                />
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <h2 className="text-2xl font-bold text-gray-900">Activity Level & Goals</h2>
            <div className="space-y-4">
              <div className="relative">
                <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center gap-2">
                  Activity Level
                  <button
                    onClick={() => setShowInfo(!showInfo)}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <Info size={16} />
                  </button>
                </label>
                {showInfo && (
                  <div className="absolute z-10 bg-white p-3 rounded-lg shadow-lg border text-sm">
                    We use this to calculate your Basal Metabolic Rate (BMR)
                  </div>
                )}
                <select
                  value={formData.activityLevel}
                  onChange={(e) => setFormData({...formData, activityLevel: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                >
                  <option value="">Select activity level</option>
                  <option value="sedentary">Sedentary: little to no exercise</option>
                  <option value="light">Light: exercise 1-3 times per week</option>
                  <option value="moderate">Moderate: exercise 4-5 times per week</option>
                  <option value="intense">Intense: daily exercise or physical job</option>
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Your Goal</label>
                <select
                  value={formData.goal}
                  onChange={(e) => setFormData({...formData, goal: e.target.value})}
                  className="w-full p-3 border rounded-lg"
                >
                  <option value="">Select goal</option>
                  <option value="lose">Lose weight</option>
                  <option value="maintain">Maintain</option>
                  <option value="gain">Gain muscle</option>
                </select>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8">
        {renderStep()}
        <div className="mt-8 flex justify-end gap-4">
          {step > 1 && (
            <button
              onClick={() => setStep(step - 1)}
              className="px-6 py-2 border rounded-lg hover:bg-gray-50"
            >
              Back
            </button>
          )}
          <button
            onClick={() => {
              if (step === 3 && isStepThreeValid) {
                handleSubmit();
              } else if ((step === 1 && isStepOneValid) || 
                        (step === 2 && isStepTwoValid)) {
                setStep(step + 1);
              }
            }}
            disabled={(step === 1 && !isStepOneValid) || 
                     (step === 2 && !isStepTwoValid) || 
                     (step === 3 && !isStepThreeValid)}
            className="px-6 py-2 bg-[#56C5B9] text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {step === 3 ? 'Done' : 'Next'}
          </button>
        </div>
      </div>
    </div>
  );
}