// frontend/src/api/food.js
import api from './config';

export const getFoodById = async (id) => {
  try {
    const response = await api.get(`/api/foods/${id}`);
    return response.data;
  } catch (error) {
    console.error('API Error:', error);
    throw error.response?.data || { message: 'Network error' };
  }
};

export const searchFoods = async (query) => {
  try {
    const response = await api.get(`/api/foods/search?query=${query}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const getAllFoods = async (page = 1, limit = 20) => {
  try {
    const response = await api.get(`/api/foods?page=${page}&limit=${limit}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};