import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, ChevronRight } from 'lucide-react';

export default function EducationDetailPage() {
  const { topicId } = useParams();
  const navigate = useNavigate();
  const [topic, setTopic] = useState(null);
  const [loading, setLoading] = useState(true);

  // Dummy data - Replace with API call
  const topics = {
    1: {
      title: 'What is Lipedema?',
      color: 'bg-pink-100',
      sections: [
        {
          title: 'Overview',
          content: `Lipedema is a chronic condition characterized by symmetric enlargement of the limbs, mainly affecting women. It's often misdiagnosed as simple obesity, but it's actually a distinct condition with its own set of characteristics and challenges.`,
          image: '/lipedema-overview.jpg'
        },
        {
          title: 'Causes',
          content: `While the exact cause of lipedema isn't fully understood, research suggests several contributing factors:
          • Hormonal changes (puberty, pregnancy, menopause)
          • Genetic predisposition
          • Inflammatory processes
          • Microvascular changes`
        },
        {
          title: 'Risk Factors',
          content: `Common risk factors include:
          • Gender (primarily affects women)
          • Family history
          • Hormonal changes
          • Obesity (can worsen symptoms)`
        }
      ]
    }
    // Add more topics
  };

  useEffect(() => {
    // Simulating API call
    setLoading(true);
    setTimeout(() => {
      setTopic(topics[topicId]);
      setLoading(false);
    }, 500);
  }, [topicId]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
      </div>
    );
  }

  if (!topic) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-900">Topic not found</h2>
          <button
            onClick={() => navigate('/')}
            className="mt-4 text-[#56C5B9] hover:underline"
          >
            Return home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <div className={`${topic.color} pt-8 pb-16 px-4`}>
        <div className="max-w-xl mx-auto">
          <button
            onClick={() => navigate(-1)}
            className="mb-4 p-2 hover:bg-black/5 rounded-full transition-colors"
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold text-gray-900">{topic.title}</h1>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-xl mx-auto px-4 -mt-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {topic.sections.map((section, index) => (
            <div key={index} className="border-b last:border-b-0">
              <div className="p-6">
                <h2 className="text-xl font-semibold mb-4">{section.title}</h2>
                
                {section.image && (
                  <img
                    src={section.image}
                    alt={section.title}
                    className="w-full h-48 object-cover rounded-lg mb-4"
                  />
                )}
                
                <div className="prose max-w-none">
                  {section.content.split('\n').map((paragraph, i) => (
                    <p key={i} className="mb-4 text-gray-600 whitespace-pre-wrap">
                      {paragraph}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation between sections */}
        <div className="mt-8 grid grid-cols-2 gap-4">
          <button
            onClick={() => navigate(`/education/${parseInt(topicId) - 1}`)}
            disabled={parseInt(topicId) <= 1}
            className="p-4 bg-white rounded-lg shadow hover:shadow-md transition-shadow disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Previous Topic
          </button>
          <button
            onClick={() => navigate(`/education/${parseInt(topicId) + 1}`)}
            className="p-4 bg-white rounded-lg shadow hover:shadow-md transition-shadow"
          >
            Next Topic
          </button>
        </div>
      </div>
    </div>
  );
}