// src/pages/LoginPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoginPage() {
  const navigate = useNavigate();
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log('LoginPage mounted');
    try {
      // Initialize Apple Sign-in
      if (window.AppleID) {
        console.log('Configuring Apple Sign-in');
        window.AppleID.auth.init({
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          scope: 'name email',
          redirectURI: process.env.REACT_APP_APPLE_REDIRECT_URI,
          state: btoa(JSON.stringify({ redirect: window.location.origin }))
        });
      } else {
        console.warn('Apple Sign-in JS not loaded');
      }
    } catch (err) {
      console.error('Error initializing Apple Sign-in:', err);
      setError(err.message);
    }
  }, []);

  const handleAppleLogin = () => {
    try {
      const authUrl = `https://appleid.apple.com/auth/authorize?` + 
        `client_id=${process.env.REACT_APP_APPLE_CLIENT_ID}&` +
        `redirect_uri=${encodeURIComponent(process.env.REACT_APP_APPLE_REDIRECT_URI)}&` +
        `response_type=code%20id_token&` +
        `scope=name%20email&` +
        `response_mode=form_post`;
        
      console.log('Redirecting to Apple auth URL:', authUrl);
      window.location.href = authUrl;
    } catch (err) {
      console.error('Error during Apple login:', err);
      setError(err.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow">
        {error && (
          <div className="bg-red-100 text-red-700 p-4 rounded-lg mb-4">
            {error}
          </div>
        )}

        <div className="text-center">
          <img
            src="/logo.png"
            alt="LipePlan Logo"
            className="h-20 mx-auto mb-6"
          />
          <h1 className="text-3xl font-bold text-gray-900">Sign in to LipePlan</h1>
          <p className="mt-2 text-gray-600">Diet companion for Lipedema patients</p>
        </div>

        <button
          onClick={handleAppleLogin}
          className="w-full flex items-center justify-center gap-3 px-4 py-3 bg-black text-white rounded-lg hover:bg-gray-900 transition-colors"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor">
            <path d="M17.05 20.28c-.98.95-2.05.88-3.08.38-1.07-.52-2.04-.53-3.16 0-1.39.68-2.12.53-3-.38C3.33 15.85 3.96 8.67 8.5 8.14c1.23-.04 2.08.48 2.83.5.75.02 1.57-.6 2.91-.58 1.61.09 2.7.8 3.35 1.93-3.03 1.83-2.54 5.42.46 6.58-.55 1.35-1.3 2.7-2 3.71zM12.03 8c-.09-2.86 2.15-5.14 4.89-5-1.35 2.89.45 5.25-4.89 5z"/>
          </svg>
          <span className="font-medium">Continue with Apple</span>
        </button>

        <p className="text-sm text-center text-gray-500">
          By continuing, you agree to our{' '}
          <a href="/terms-of-service" className="text-[#56C5B9] hover:underline">
            Terms of Service
          </a>{' '}
          and{' '}
          <a href="/privacy-policy" className="text-[#56C5B9] hover:underline">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  );
}