import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Clock, Users, Bookmark, AlertCircle } from 'lucide-react';
import { getRecipe } from '../api/recipe';

export default function RecipeDetailPage() {
  const { recipeId } = useParams();
  const navigate = useNavigate();
  const [recipe, setRecipe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    loadRecipe();
  }, [recipeId]);

  const loadRecipe = async () => {
    try {
      setLoading(true);
      const data = await getRecipe(recipeId);
      setRecipe(data);
    } catch (error) {
      console.error('Error loading recipe:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
      </div>
    );
  }

  if (!recipe) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-bold text-gray-900">Recipe not found</h2>
          <button
            onClick={() => navigate('/')}
            className="mt-4 text-[#56C5B9] hover:underline"
          >
            Return home
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Image Header */}
      <div className="relative h-72">
        <img
          src={recipe.image}
          alt={recipe.title}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <button
          onClick={() => navigate(-1)}
          className="absolute top-8 left-4 text-white p-2 hover:bg-white/10 rounded-full transition-colors"
        >
          <ArrowLeft size={24} />
        </button>
      </div>

      {/* Content */}
      <div className="max-w-xl mx-auto px-4 -mt-16 relative">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6">
            {/* Title and Save Button */}
            <div className="flex justify-between items-start mb-4">
              <h1 className="text-2xl font-bold text-gray-900">{recipe.title}</h1>
              <button
                onClick={() => setSaved(!saved)}
                className={`p-2 rounded-full ${
                  saved ? 'text-[#56C5B9]' : 'text-gray-400'
                }`}
              >
                <Bookmark size={24} fill={saved ? '#56C5B9' : 'none'} />
              </button>
            </div>

            {/* Recipe Meta */}
            <div className="flex gap-6 mb-6">
              <div className="flex items-center gap-2">
                <Clock size={20} className="text-gray-400" />
                <span className="text-sm text-gray-600">
                  {recipe.preparationTime + recipe.cookingTime} mins
                </span>
              </div>
              <div className="flex items-center gap-2">
                <Users size={20} className="text-gray-400" />
                <span className="text-sm text-gray-600">
                  {recipe.servings} servings
                </span>
              </div>
            </div>

            {/* Match Score */}
            {recipe.matchScore && (
              <div className="mb-6 p-4 bg-green-50 rounded-lg">
                <div className="flex items-center gap-2 mb-2">
                  <div className="h-2 flex-1 bg-white rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-green-500 rounded-full transition-all"
                      style={{ width: `${recipe.matchScore}%` }}
                    />
                  </div>
                  <span className="text-sm font-medium text-green-700">
                    {recipe.matchScore}% match
                  </span>
                </div>
                <p className="text-sm text-green-600">
                  This recipe matches your food preferences
                </p>
              </div>
            )}

            {/* Ingredients */}
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4">Ingredients</h2>
              <ul className="space-y-2">
                {recipe.ingredients.map((ingredient, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-2 p-3 bg-gray-50 rounded-lg"
                  >
                    <span className="text-gray-600">
                      {ingredient.amount} {ingredient.unit}
                    </span>
                    <span className="font-medium">{ingredient.food.name}</span>
                    {ingredient.food.status === 'avoid' && (
                      <AlertCircle size={16} className="text-red-500 ml-auto" />
                    )}
                  </li>
                ))}
              </ul>
            </div>

            {/* Instructions */}
            <div className="mb-8">
              <h2 className="text-xl font-semibold mb-4">Instructions</h2>
              <ol className="space-y-4">
                {recipe.instructions.map((instruction, index) => (
                  <li key={index} className="flex gap-4">
                    <span className="flex-none w-8 h-8 flex items-center justify-center bg-[#56C5B9] text-white rounded-full">
                      {index + 1}
                    </span>
                    <p className="flex-1 text-gray-600 pt-1">{instruction}</p>
                  </li>
                ))}
              </ol>
            </div>

            {/* Nutritional Info */}
            <div>
              <h2 className="text-xl font-semibold mb-4">Nutritional Information</h2>
              <div className="grid grid-cols-4 gap-4">
                {Object.entries(recipe.nutritionalInfo).map(([key, value]) => (
                  <div
                    key={key}
                    className="text-center p-4 bg-gray-50 rounded-lg"
                  >
                    <div className="text-xl font-bold text-[#56C5B9]">
                      {value}{key === 'calories' ? '' : 'g'}
                    </div>
                    <div className="text-sm text-gray-500 capitalize">
                      {key}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}