import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Heart, MessageCircle, Send, ArrowLeft, MoreVertical, Trash } from 'lucide-react';
import { useAuth } from '../context/AuthContext';
import api from '../api/config';

export default function PostDetailPage() {
  const { postId } = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();
  const [post, setPost] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showOptions, setShowOptions] = useState(null); // for post/comment menu

  useEffect(() => {
    loadPost();
  }, [postId]);

  const loadPost = async () => {
    try {
      const response = await api.get(`/api/posts/${postId}`);
      setPost(response.data);
    } catch (error) {
      setError('Failed to load post');
      console.error('Error loading post:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleLike = async () => {
    try {
      const response = await api.post(`/api/posts/${postId}/like`);
      setPost(response.data);
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleComment = async (e) => {
    e.preventDefault();
    if (!newComment.trim()) return;

    try {
      const response = await api.post(`/api/posts/${postId}/comment`, {
        content: newComment.trim()
      });
      setPost(response.data);
      setNewComment('');
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleDeletePost = async () => {
    if (!window.confirm('Are you sure you want to delete this post?')) return;

    try {
      await api.delete(`/api/posts/${postId}`);
      navigate('/feed');
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    if (!window.confirm('Are you sure you want to delete this comment?')) return;

    try {
      const response = await api.delete(`/api/posts/${postId}/comments/${commentId}`);
      setPost(response.data);
    } catch (error) {
      console.error('Error deleting comment:', error);
    }
  };

  const formatTimestamp = (timestamp) => {
    return new Date(timestamp).toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="bg-red-100 text-red-700 p-4 rounded-lg">
          {error || 'Post not found'}
        </div>
      </div>
    );
  }

  const canDeletePost = user.role === 'admin' || post.user._id === user._id;
  const isLiked = post.likes.includes(user._id);

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-4 px-4">
        <div className="max-w-xl mx-auto flex items-center">
          <button onClick={() => navigate(-1)} className="text-white">
            <ArrowLeft size={24} />
          </button>
          <h1 className="ml-4 text-xl font-bold text-white">Post Details</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4">
        <div className="bg-white rounded-lg shadow-sm overflow-hidden mt-4">
          {/* Post Header */}
          <div className="p-4 flex items-center justify-between">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 bg-[#56C5B9] rounded-full flex items-center justify-center">
                <span className="text-white font-semibold">
                  {post.user?.username?.[0]?.toUpperCase()}
                </span>
              </div>
              <div>
                <h3 className="font-semibold">{post.user?.username}</h3>
                <p className="text-xs text-gray-500">
                  {formatTimestamp(post.createdAt)}
                </p>
              </div>
            </div>
            
            {canDeletePost && (
              <div className="relative">
                <button
                  onClick={() => setShowOptions(post._id)}
                  className="p-2 hover:bg-gray-100 rounded-full"
                >
                  <MoreVertical size={20} />
                </button>
                {showOptions === post._id && (
                  <div className="absolute right-0 mt-2 bg-white rounded-lg shadow-lg z-10">
                    <button
                      onClick={handleDeletePost}
                      className="flex items-center gap-2 p-3 text-red-600 hover:bg-red-50 w-full"
                    >
                      <Trash size={16} />
                      <span>Delete Post</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Post Content */}
          {post.image && (
            <img
              src={post.image.startsWith('http') 
                ? post.image 
                : `${process.env.REACT_APP_API_URL}${post.image}`}
              alt="Post content"
              className="w-full h-96 object-cover"
            />
          )}

          <div className="p-4">
            <p className="text-gray-700 mb-4">{post.content}</p>

            {/* Foods Tags */}
            {post.foods?.length > 0 && (
              <div className="mb-4 flex flex-wrap gap-2">
                {post.foods.map(food => (
                  <span 
                    key={food._id} 
                    className="px-2 py-1 bg-[#56C5B9]/10 text-[#56C5B9] rounded-full text-sm"
                  >
                    {food.name}
                  </span>
                ))}
              </div>
            )}

            {/* Like & Comment Counts */}
            <div className="flex items-center gap-6">
              <button
                onClick={handleLike}
                className="flex items-center gap-2 text-gray-500 hover:text-[#56C5B9]"
              >
                <Heart
                  size={20}
                  className={isLiked ? 'fill-[#56C5B9] text-[#56C5B9]' : ''}
                />
                <span className="text-sm">{post.likes?.length || 0}</span>
              </button>
              <div className="flex items-center gap-2 text-gray-500">
                <MessageCircle size={20} />
                <span className="text-sm">{post.comments?.length || 0}</span>
              </div>
            </div>
          </div>

          {/* Comments Section */}
          <div className="border-t">
            <div className="p-4">
              <h3 className="font-semibold mb-4">Comments</h3>
              <div className="space-y-4">
                {post.comments?.map((comment) => (
                  <div key={comment._id} className="flex gap-3">
                    <div className="w-8 h-8 bg-gray-100 rounded-full flex items-center justify-center">
                      <span className="text-sm font-medium">
                        {comment.user?.username?.[0]?.toUpperCase()}
                      </span>
                    </div>
                    <div className="flex-1">
                      <div className="flex items-start justify-between">
                        <div>
                          <p className="text-sm">
                            <span className="font-medium">{comment.user?.username}</span>{' '}
                            {comment.content}
                          </p>
                          <p className="text-xs text-gray-500">
                            {formatTimestamp(comment.createdAt)}
                          </p>
                        </div>
                        {(user.role === 'admin' || comment.user._id === user._id) && (
                          <button
                            onClick={() => handleDeleteComment(comment._id)}
                            className="text-red-500 hover:text-red-700 p-1"
                          >
                            <Trash size={16} />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Add Comment Form */}
            <form onSubmit={handleComment} className="p-4 border-t flex gap-2">
              <input
                type="text"
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
                className="flex-1 px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#56C5B9]"
              />
              <button
                type="submit"
                disabled={!newComment.trim()}
                className="p-2 text-[#56C5B9] hover:bg-[#56C5B9]/10 rounded-lg disabled:opacity-50"
              >
                <Send size={20} />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}