import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search, Users, MessageSquare, FileText, X, Check, Ban } from 'lucide-react';
import api from '../api/config';
import RecipeManagement from '../components/RecipeManagement';

export default function AdminDashboard() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('users');
  const [searchQuery, setSearchQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [pendingPosts, setPendingPosts] = useState([]);
  const [pendingComments, setPendingComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    loadData();
  }, [activeTab]);

  const loadData = async () => {
    setLoading(true);
    setError('');
    try {
      switch (activeTab) {
        case 'users':
          const { data: usersData } = await api.get('/api/admin/users');
          setUsers(usersData);
          break;
        case 'posts':
          const { data: postsData } = await api.get('/api/admin/posts/pending');
          setPendingPosts(postsData);
          break;
        case 'comments':
          const { data: commentsData } = await api.get('/api/admin/comments/pending');
          setPendingComments(commentsData);
          break;
      }
    } catch (error) {
      console.error('Error loading data:', error);
      setError('Failed to load data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUserAction = async (userId, action) => {
    try {
      await api.post(`/api/admin/users/${userId}/${action}`);
      loadData();
      setError('');
    } catch (error) {
      console.error(`Error ${action}ing user:`, error);
      setError(`Failed to ${action} user. Please try again.`);
    }
  };

  const handleContentAction = async (id, type, action) => {
    try {
      await api.post(`/api/admin/${type}/${id}/${action}`);
      loadData();
      setError('');
    } catch (error) {
      setError(`Failed to ${action} ${type}. Please try again.`);
    }
  };

const UsersSection = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async (query) => {
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }

    try {
      setSearching(true);
      const response = await api.get(`/api/admin/users/search?query=${query}`);
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching users:', error);
    } finally {
      setSearching(false);
    }
  };

  // Debounce search to avoid too many API calls
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchQuery) {
        handleSearch(searchQuery);
      }
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  const handleUserAction = async (userId, action) => {
    try {
      await api.post(`/api/admin/users/${userId}/${action}`);
      // Refresh search results
      handleSearch(searchQuery);
    } catch (error) {
      console.error(`Error ${action}ing user:`, error);
    }
  };

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Search users by email or username..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="w-full p-3 pr-10 border rounded-lg"
        />
        <Search className="absolute right-3 top-3 text-gray-400" size={20} />
      </div>

      {searching ? (
        <div className="flex justify-center py-8">
          <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-[#56C5B9]" />
        </div>
      ) : (
        searchQuery && (
          <div className="space-y-2">
            {searchResults.length > 0 ? (
              searchResults.map(user => (
                <div key={user._id} className="bg-white p-4 rounded-lg shadow flex justify-between items-center">
                  <div>
                    <button
                      onClick={() => navigate(`/profile/${user._id}`)}
                      className="font-medium text-[#56C5B9] hover:underline"
                    >
                      {user.username}
                    </button>
                    <p className="text-sm text-gray-500">{user.email}</p>
                    {user.isBanned && (
                      <span className="text-xs bg-red-100 text-red-600 px-2 py-1 rounded-full">
                        Banned
                      </span>
                    )}
                  </div>
                  <button
                    onClick={() => handleUserAction(user._id, user.isBanned ? 'unban' : 'ban')}
                    className={`p-2 rounded-lg ${
                      user.isBanned
                        ? 'bg-green-50 text-green-600'
                        : 'bg-red-50 text-red-600'
                    }`}
                  >
                    <Ban size={20} />
                  </button>
                </div>
              ))
            ) : (
              <div className="text-center py-8 text-gray-500">
                No users found matching "{searchQuery}"
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};

  const PendingPostsSection = () => (
    <div className="space-y-4">
      {pendingPosts.map(post => (
        <div key={post._id} className="bg-white p-4 rounded-lg shadow">
          <div className="flex justify-between items-start mb-4">
            <div>
              <button
                onClick={() => navigate(`/profile/${post.user._id}`)}
                className="font-medium text-[#56C5B9] hover:underline"
              >
                {post.user.username}
              </button>
              <p className="text-xs text-gray-500">
                {new Date(post.createdAt).toLocaleString()}
              </p>
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => handleContentAction(post._id, 'posts', 'approve')}
                className="p-2 bg-green-50 text-green-600 rounded-lg hover:bg-green-100"
              >
                <Check size={20} />
              </button>
              <button
                onClick={() => handleContentAction(post._id, 'posts', 'reject')}
                className="p-2 bg-red-50 text-red-600 rounded-lg hover:bg-red-100"
              >
                <X size={20} />
              </button>
            </div>
          </div>
          <p className="text-gray-700">{post.content}</p>
          {post.image && (
            <img
              src={post.image}
              alt="Post content"
              className="mt-2 rounded-lg max-h-48 object-cover"
            />
          )}
        </div>
      ))}
      {pendingPosts.length === 0 && (
        <div className="text-center py-8 text-gray-500">
          No pending posts to review
        </div>
      )}
    </div>
  );

  const PendingCommentsSection = () => {
    if (!pendingComments) {
      return null;
    }

    return (
      <div className="space-y-4">
        {pendingComments.map(comment => (
          <div key={comment._id} className="bg-white p-4 rounded-lg shadow">
            <div className="flex justify-between items-start mb-4">
              <div>
                <div className="flex items-center gap-2 mb-1">
                  <button
                    onClick={() => navigate(`/profile/${comment.user._id}`)}
                    className="font-medium text-[#56C5B9] hover:underline"
                  >
                    {comment.user.username}
                  </button>
                  <span className="text-xs text-gray-500">
                    commented on post by {comment.postAuthor}
                  </span>
                </div>
                <p className="text-xs text-gray-500">
                  {new Date(comment.createdAt).toLocaleString()}
                </p>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={() => handleContentAction(
                    `${comment.postId}/${comment._id}`,
                    'comments',
                    'approve'
                  )}
                  className="p-2 bg-green-50 text-green-600 rounded-lg hover:bg-green-100"
                >
                  <Check size={20} />
                </button>
                <button
                  onClick={() => handleContentAction(
                    `${comment.postId}/${comment._id}`,
                    'comments',
                    'reject'
                  )}
                  className="p-2 bg-red-50 text-red-600 rounded-lg hover:bg-red-100"
                >
                  <X size={20} />
                </button>
              </div>
            </div>
            <p className="text-gray-700 mb-2">{comment.content}</p>
            <div className="bg-gray-50 p-3 rounded-lg text-sm text-gray-600">
              <p className="font-medium mb-1">On post:</p>
              <p>{comment.postContent}</p>
            </div>
          </div>
        ))}
        {pendingComments.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No pending comments to review
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-2xl font-bold text-white">Admin Dashboard</h1>
        </div>
      </div>

      <div className="max-w-4xl mx-auto px-4 -mt-8">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="flex border-b">
            <button
              onClick={() => setActiveTab('users')}
              className={`flex items-center gap-2 px-4 py-3 ${
                activeTab === 'users'
                  ? 'text-[#56C5B9] border-b-2 border-[#56C5B9]'
                  : 'text-gray-500'
              }`}
            >
              <Users size={20} />
              Users
            </button>
            <button
              onClick={() => setActiveTab('posts')}
              className={`flex items-center gap-2 px-4 py-3 ${
                activeTab === 'posts'
                  ? 'text-[#56C5B9] border-b-2 border-[#56C5B9]'
                  : 'text-gray-500'
              }`}
            >
              <FileText size={20} />
              Pending Posts
            </button>
            <button
              onClick={() => setActiveTab('comments')}
              className={`flex items-center gap-2 px-4 py-3 ${
                activeTab === 'comments'
                  ? 'text-[#56C5B9] border-b-2 border-[#56C5B9]'
                  : 'text-gray-500'
              }`}
            >
              <MessageSquare size={20} />
              Pending Comments
            </button>
          </div>

          <div className="p-4">
            {error && (
              <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-lg">
                {error}
              </div>
            )}

            {loading ? (
              <div className="flex justify-center py-12">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
              </div>
            ) : (
              <>
                {activeTab === 'users' && <UsersSection />}
                {activeTab === 'posts' && <PendingPostsSection />}
                {activeTab === 'comments' && <PendingCommentsSection />}
              </>
            )}
          </div>
        </div>

        {/* Separate Recipe Management Section */}
        <div className="mt-8 bg-white rounded-lg shadow-lg overflow-hidden">
          <RecipeManagement />
        </div>
      </div>
    </div>
  );
}