import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, ChevronRight } from 'lucide-react';
import { getUserPreferences } from '../api/preferences';

export default function PreferenceListPage() {
  const { status } = useParams();
  const navigate = useNavigate();
  const [foods, setFoods] = useState([]);
  const [loading, setLoading] = useState(true);

  const titles = {
    ok: "Good for Me",
    sensitive: "Cautious",
    avoid: "Avoid"
  };

  const colors = {
    ok: "bg-green-50 text-green-700",
    sensitive: "bg-yellow-50 text-yellow-700",
    avoid: "bg-red-50 text-red-700"
  };

  useEffect(() => {
    loadFoods();
  }, [status]);

  const loadFoods = async () => {
    try {
      const prefs = await getUserPreferences();
      const filteredFoods = prefs.filter(pref => pref.status === status);
      setFoods(filteredFoods);
    } catch (error) {
      console.error('Error loading preferences:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className={`${colors[status]} pt-8 pb-16 px-4`}>
        <div className="max-w-xl mx-auto">
          <button 
            onClick={() => navigate(-1)}
            className="mb-4"
          >
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold">{titles[status]}</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        {loading ? (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-lg overflow-hidden">
            {foods.map(pref => (
              <button
                key={pref.food._id}
                onClick={() => navigate(`/food/${pref.food._id}`)}
                className="w-full p-4 flex items-center justify-between border-b last:border-b-0 hover:bg-gray-50"
              >
                <div>
                  <h3 className="font-medium">{pref.food.name}</h3>
                  <p className="text-sm text-gray-500">{pref.food.category}</p>
                </div>
                <ChevronRight size={20} className="text-gray-400" />
              </button>
            ))}
            {foods.length === 0 && (
              <div className="p-8 text-center text-gray-500">
                No foods in this category yet
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}