import api from './config';

export const getRecommendations = async () => {
  try {
    const response = await api.get('/api/recipes/recommendations');
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Error fetching recommendations' };
  }
};

export const getRecipe = async (id) => {
  try {
    const response = await api.get(`/api/recipes/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Error fetching recipe' };
  }
};