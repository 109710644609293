// src/api/preferences.js
import api from './config';

export const getUserPreferences = async () => {
  try {
    const response = await api.get('/api/preferences');
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};

export const updatePreference = async (foodId, status, notes, symptoms = []) => {
  try {
    const response = await api.post('/api/preferences', {
      foodId,
      status,
      notes,
      symptoms
    });
    return response.data;
  } catch (error) {
    throw error.response?.data || { message: 'Network error' };
  }
};