import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './context/AuthContext';

// Pages
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import SearchPage from './pages/SearchPage';
import CreatePostPage from './pages/CreatePostPage';
import FeedPage from './pages/FeedPage';
import ProfilePage from './pages/ProfilePage';
import EditProfilePage from './pages/EditProfilePage';
import OnboardingFlow from './pages/OnboardingFlow';
import AdminDashboard from './pages/AdminDashboard';
import AppleCallback from './pages/AppleCallback';
import FoodDetailPage from './pages/FoodDetailPage';
import PreferenceListPage from './pages/PreferenceListPage';
import SettingsPage from './pages/SettingsPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import PostDetailPage from './pages/PostDetailPage'; 
import EducationDetailPage from './pages/EducationDetailPage';
import RecipeDetailPage from './pages/RecipeDetailPage'; 

// Components
import Navigation from './components/Navigation';

function ProtectedRoute({ children }) {
  const { user } = useAuth();
  if (!user) return <Navigate to="/login" replace />;
  return children;
}

function AdminRoute({ children }) {
  const { user } = useAuth();
  if (!user?.role === 'admin') return <Navigate to="/" replace />;
  return children;
}

function AppRoutes() {
  const { user } = useAuth();

  return (
    <div className="min-h-screen bg-gray-50">
      <Routes>
        {/* Public Routes */}
        <Route path="/login" element={<LoginPage />} />
        <Route path="/auth/apple/callback" element={<AppleCallback />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/terms-of-service" element={<TermsOfServicePage />} />

<Route path="/education/:topicId" element={<EducationDetailPage />} />
<Route path="/recipes/:recipeId" element={<RecipeDetailPage />} />

        {/* Protected Routes */}
        <Route path="/" element={
          <ProtectedRoute>
            <HomePage />
          </ProtectedRoute>
        } />
        <Route path="/edit-profile" element={
          <ProtectedRoute>
            <EditProfilePage />
          </ProtectedRoute>
        } />
        <Route path="/admin" element={
          <ProtectedRoute>
            <AdminRoute>
              <AdminDashboard />
            </AdminRoute>
          </ProtectedRoute>
        } />
        <Route path="/onboarding" element={
          <ProtectedRoute>
            <OnboardingFlow />
          </ProtectedRoute>
        } />
        <Route path="/search" element={
          <ProtectedRoute>
            <SearchPage />
          </ProtectedRoute>
        } />
        <Route path="/create" element={
          <ProtectedRoute>
            <CreatePostPage />
          </ProtectedRoute>
        } />
        <Route path="/feed" element={
          <ProtectedRoute>
            <FeedPage />
          </ProtectedRoute>
        } />
        <Route path="/profile" element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        } />
        <Route path="/settings" element={
          <ProtectedRoute>
            <SettingsPage />
          </ProtectedRoute>
        } />
        <Route path="/food/:id" element={
          <ProtectedRoute>
            <FoodDetailPage />
          </ProtectedRoute>
        } />
        <Route path="/preferences/:status" element={
          <ProtectedRoute>
            <PreferenceListPage />
          </ProtectedRoute>
        } />

<Route path="/profile/:userId" element={
  <ProtectedRoute>
	<AdminRoute>
    <ProfilePage />
	</AdminRoute>
  </ProtectedRoute>
} />
<Route path="/posts/:postId" element={
  <ProtectedRoute>
    <PostDetailPage />
  </ProtectedRoute>
} />

        {/* Catch all */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {user && !window.location.pathname.includes('/onboarding') && (
        <Navigation />
      )}
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
}