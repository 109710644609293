import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Search as SearchIcon, Filter } from 'lucide-react';
import { getAllFoods } from '../api/food';
import { getUserPreferences, updatePreference } from '../api/preferences';

export default function SearchPage() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [foods, setFoods] = useState([]);
  const [loading, setLoading] = useState(true);
  const [preferences, setPreferences] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const loader = useRef(null);

  const categories = ['all', 'Fruits', 'Vegetables', 'Proteins', 'Beverages', 'Grains', 'Dairy', 'Legumes', 'Nuts and Seeds', 'Snacks and Sweets', 'Condiments'];

const loadInitialData = async () => {
  try {
    setLoading(true);
    const [foodData, prefsData] = await Promise.all([
      getAllFoods(1, 20, searchQuery, selectedCategory),  // Add search params
      getUserPreferences()
    ]);
    setFoods(foodData.foods);
    setHasMore(foodData.hasMore);
    
    const prefsMap = {};
    prefsData.forEach(pref => {
      prefsMap[pref.food._id] = pref.status;
    });
    setPreferences(prefsMap);
  } catch (error) {
    console.error(error);
  } finally {
    setLoading(false);
  }
};

const loadMore = async () => {
  if (!hasMore || loading) return;
  
  try {
    setLoading(true);
    const nextPage = page + 1;
    const foodData = await getAllFoods(nextPage, 20, searchQuery, selectedCategory);
    
    setFoods(prev => [...prev, ...foodData.foods]);
    setHasMore(foodData.hasMore);
    setPage(nextPage);
  } catch (error) {
    console.error('Error loading more foods:', error);
  } finally {
    setLoading(false);
  }
};

  const handlePreferenceUpdate = async (foodId, status) => {
    try {
      await updatePreference(foodId, status);
      setPreferences(prev => ({
        ...prev,
        [foodId]: status
      }));
      loadInitialData(); // Reload to get updated stats
    } catch (error) {
      console.error('Error updating preference:', error);
    }
  };

  // Intersection Observer callback
  const handleObserver = useCallback((entries) => {
    const target = entries[0];
    if (target.isIntersecting && hasMore) {
      loadMore();
    }
  }, [hasMore]);

  // Set up the intersection observer
  useEffect(() => {
    const option = {
      root: null,
      rootMargin: "20px",
      threshold: 0
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (loader.current) observer.observe(loader.current);
    
    return () => {
      if (loader.current) observer.unobserve(loader.current);
    };
  }, [handleObserver]);

  // Reset page when search query or category changes
  useEffect(() => {
    setFoods([]);
    setPage(1);
    setHasMore(true);
    loadInitialData();
  }, [searchQuery, selectedCategory]);

  const FoodCard = ({ food }) => {
    const handleCardClick = (e) => {
      if (!e.target.closest('button')) {
        navigate(`/food/${food._id}`);
      }
    };

    return (
      <div 
        onClick={handleCardClick}
        className="bg-white p-4 rounded-lg shadow cursor-pointer hover:shadow-md transition-shadow"
      >
        <div className="flex justify-between items-start mb-3">
          <div>
            <h3 className="font-semibold text-lg">{food.name}</h3>
            <p className="text-sm text-gray-500">{food.category}</p>
          </div>
          {food.antiInflammatory && (
            <span className="bg-green-100 text-green-800 text-xs px-2 py-1 rounded-full">
              Anti-inflammatory
            </span>
          )}
        </div>
        
        {food.stats && (
          <div className="grid grid-cols-3 gap-2 p-3 bg-gray-50 rounded-lg mb-4">
            <div className="text-center">
              <p className="text-sm font-bold text-green-600">
                {Math.round((food.stats.ok / (food.stats.total || 1)) * 100)}%
              </p>
              <p className="text-xs text-gray-500">Good for Me</p>
            </div>
            <div className="text-center">
              <p className="text-sm font-bold text-yellow-600">
                {Math.round((food.stats.sensitive / (food.stats.total || 1)) * 100)}%
              </p>
              <p className="text-xs text-gray-500">Cautious</p>
            </div>
            <div className="text-center">
              <p className="text-sm font-bold text-red-600">
                {Math.round((food.stats.avoid / (food.stats.total || 1)) * 100)}%
              </p>
              <p className="text-xs text-gray-500">Avoid</p>
            </div>
          </div>
        )}

        <div className="flex gap-2">
          <button
            onClick={() => handlePreferenceUpdate(food._id, 'ok')}
            className={`flex-1 py-2 rounded-lg text-sm ${
              preferences[food._id] === 'ok'
                ? 'bg-green-500 text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Good for Me
          </button>
          <button
            onClick={() => handlePreferenceUpdate(food._id, 'sensitive')}
            className={`flex-1 py-2 rounded-lg text-sm ${
              preferences[food._id] === 'sensitive'
                ? 'bg-yellow-500 text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Cautious
          </button>
          <button
            onClick={() => handlePreferenceUpdate(food._id, 'avoid')}
            className={`flex-1 py-2 rounded-lg text-sm ${
              preferences[food._id] === 'avoid'
                ? 'bg-red-500 text-white'
                : 'bg-gray-100 hover:bg-gray-200'
            }`}
          >
            Avoid
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 pb-32">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto">
          <h1 className="text-2xl font-bold text-white mb-4">Find Foods</h1>
          <div className="relative">
            <input
              type="text"
              placeholder="Search foods..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-4 pr-12 rounded-lg shadow-lg"
            />
            <SearchIcon className="absolute right-4 top-4 text-gray-400" size={20} />
          </div>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 -mt-8">
        <div className="bg-white rounded-lg shadow-lg p-4">
          <div className="flex items-center gap-2 mb-3">
            <Filter size={20} className="text-[#56C5B9]" />
            <h2 className="font-semibold">Categories</h2>
          </div>
          <div className="flex flex-wrap gap-2">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-3 py-1 rounded-full text-sm ${
                  selectedCategory === category
                    ? 'bg-[#56C5B9] text-white'
                    : 'bg-gray-100 hover:bg-gray-200'
                }`}
              >
                {category.charAt(0).toUpperCase() + category.slice(1)}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 mt-6 pb-20">
        <div className="space-y-4">
          {foods
            .filter(food => 
              food.name.toLowerCase().includes(searchQuery.toLowerCase()) &&
              (selectedCategory === 'all' || food.category === selectedCategory)
            )
            .map(food => (
              <FoodCard key={food._id} food={food} />
            ))}
            
          {/* Loading indicator at bottom */}
          <div ref={loader} className="py-4">
            {loading && (
              <div className="flex justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#56C5B9]" />
              </div>
            )}
          </div>

          {foods.length === 0 && !loading && (
            <div className="text-center py-8 text-gray-500">
              No foods found matching your search
            </div>
          )}
        </div>
      </div>
    </div>
  );
}