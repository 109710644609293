import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useAuth } from '../context/AuthContext';

export default function PrivacyPolicyPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  
  const handleBack = () => {
    if (location.state?.from) {
      navigate(location.state.from);
    } else if (user) {
      navigate(-1);
    } else {
      navigate('/login');
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="bg-[#56C5B9] pt-8 pb-16 px-4">
        <div className="max-w-xl mx-auto flex items-center gap-4">
          <button onClick={handleBack} className="text-white">
            <ArrowLeft size={24} />
          </button>
          <h1 className="text-2xl font-bold text-white">Privacy Policy</h1>
        </div>
      </div>

      <div className="max-w-xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-lg p-6 space-y-6">
          <section>
            <h2 className="text-xl font-bold mb-3">1. Information We Collect</h2>
            <p className="text-gray-700 mb-2">We collect the following information when you use our app:</p>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Account information (name, email) through Google or Apple Sign-in</li>
              <li>Food preferences and dietary information you provide</li>
              <li>Posts and comments you share in the community</li>
              <li>Usage data and interaction with the app</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">2. How We Use Your Information</h2>
            <p className="text-gray-700 mb-2">We use your information to:</p>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Provide personalized dietary recommendations</li>
              <li>Improve our app and user experience</li>
              <li>Enable community features and interaction</li>
              <li>Send important updates about our service</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">3. Data Protection</h2>
            <p className="text-gray-700">
              We implement appropriate security measures to protect your personal information. 
              Data is encrypted during transmission and storage. We regularly review our 
              security practices to ensure your information remains protected.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">4. Your Rights</h2>
            <p className="text-gray-700 mb-2">You have the right to:</p>
            <ul className="list-disc pl-5 text-gray-700 space-y-1">
              <li>Access your personal data</li>
              <li>Correct inaccurate data</li>
              <li>Request deletion of your data</li>
              <li>Export your data</li>
              <li>Withdraw consent at any time</li>
            </ul>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">5. Third-Party Services</h2>
            <p className="text-gray-700">
              We use Google and Apple Sign-in for authentication. Please review their 
              respective privacy policies to understand how they handle your information.
            </p>
          </section>

          <section>
            <h2 className="text-xl font-bold mb-3">6. Contact Us</h2>
            <p className="text-gray-700">
              If you have any questions about this Privacy Policy, please contact us at lipeplandietapp@gmail.com
            </p>
          </section>

          <div className="text-sm text-gray-500 pt-4">
            Last updated: {new Date().toLocaleDateString()}
          </div>
        </div>
      </div>
    </div>
  );
}